<template>
  <Modal :modelValue="show" @update:modelValue="$emit('close')">
        <div class="estimate_preview">
            <preview :container-id="estimate" :mode="'my-estimates'" />
        </div>
<!--      <h4 class="header__title">Estimate: {{ estimate?.id_with_prefix}}</h4>-->
<!--      <h5 class="header__number">Number of items: {{ estimate?.items.length }}</h5>-->
<!--      <div class="estimate_preview__body">-->
<!--        <div class="estimate_preview__container">-->
<!--          <div class="estimate_preview__content">-->
<!--            <ProductItem v-for="(item, index) in estimate?.items" :key="index"-->
<!--                         :product="item"-->
<!--            ></ProductItem>-->
<!--            <div class="estimate_preview__summary">-->
<!--              <Receipt title="Total Estimate" :summary="summary" v-if="summary"/>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </Modal>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import Button from '@/components/form/Button'
import Modal from '@/components/elements/Modal'
// import ProductItem from "@/components/elements/ProductItem";
// import Receipt from "@/components/item-wizard/components/estimate/item/summary/Receipt";
import {useStore} from "vuex";
import preview from '@/components/item-wizard/components/orders/preview'


export default ({
  components: {
    Button,
    Modal,
    // ProductItem,
    // Receipt,
    preview
  },
  props: ['estimate', 'show', 'summary'],
  emits: ['close'],
  setup(props, {emit}) {
    return {
    }
  }
})
</script>

<style scoped lang="scss">

.order-info-popup {
  padding: 0;
  max-width: 100%;
  max-height: 70vh;
  overflow-y: scroll;
}


.estimate_preview {

  width: 650px;
  max-width: 95vw;

  .header {
    &__title {
      padding: 20px 40px 0;
      font-weight: bold;
      font-size: 25px;
      font-family: Sansation;
      text-align: left;
    }
    &__number {
      padding: 12px 40px;
      box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
      font-weight: bold;
      font-size: 19px;
      font-family: Sansation;
      text-align: left;
    }
  }
  &__container {
    height: calc(100vh - 300px);
    overflow-y: scroll;
    > div {
      height: auto;
    }
  }
  &__summary {
    padding: 20px 40px 40px;
    font-weight: normal;
    font-size: 19px;
  }
}
@media (max-width: 768px) {
  .estimate_preview-modal{
    &.show {
      z-index:99999999 !important;
    }
   /* &.less_items{*/
       top: 0;
    /*}*/

    height : 100vh;
  }
  ::v-deep .modal {
    padding: 0;
    width: 100%;
  }
  .estimate_preview {
    .header {

    }
    .header {
      &__title {
        padding: 8px 20px 0 20px;
        font-size: 18px;
        /*height: 25px;*/
        display: flex;
        align-items: center;
      }
      &__number {
        padding: 0 20px 8px 20px;
        display: flex;
        align-items: center;
       /* height: 25px;*/
        font-size: 12px;
      }
    }
    &__container {
      height: auto;
      max-height: calc(100vh - 50px);
    }
    &__summary {
      padding: 20px 20px 70px 20px;
      font-size: 15px;
    }
  }
}
</style>
