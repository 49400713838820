<template>
  <div v-if="items && items.length" class="cards">
    <Card
        v-for="(item, index) in items"
        :key="`card-${index}`" :data="item"
        class="cards__item"
        @clone="cloneEstimate"
        @delete="setEstimateToBeDeleted"
        @get="getEstimate"
        @onClick="goToEstimate(item)"
        @specification="goToSpecification(item)"
        @close="infoId = null"
    />
    <DeleteConfirmationModal v-model="showDeleteConfirmation" @deleteEstimate="deleteEstimate"/>
    <EstimatePreview :estimate="infoId" v-if="infoId" :show="infoId !== null" @close="infoId = null"/>
  </div>
</template>

<script>
import Card from './cards/Card'
import DeleteConfirmationModal from '@/components/item-wizard/components/modals/DeleteConfirmationModal'
import EstimatePreview from "@/components/dashboard/EstimatePreview";
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import api from '@/services/estimate/dashboard.service.js'
import {reactive, ref} from 'vue'

export default ({
  props: ['items'],
  emits: ['change'],
  components: {
    Card,
    EstimatePreview,
    DeleteConfirmationModal
  },
  setup(props, {emit}) {
    const idOfTheEstimateToBeDeleted = ref(0)
    const store = useStore()
    const router = useRouter()
    const showDeleteConfirmation = ref(false)
    const infoId = ref(null)

    function goToEstimate(item) {
      router.push({name: 'estimate-item', params: {type: 'my-estimates', id: item.container_id}})
    }

    function goToSpecification(item) {
      router.push({name: 'specification', params: {type: 'my-estimates', id: item.container_id}})
    }

    const estimate = reactive({})
    const summary = reactive({})
    const previewShow = ref(false)
    const getEstimate = async (id) => {
      infoId.value = id
      // [estimate.value, summary.value] = await Promise.all([api.getEstimate(id), api.getEstimateSummary(id)]);
      // previewShow.value = true
    }
    const cloneEstimate = async (id) => {
      store.commit('setField', {
        field: 'loading', value: true,
      })

      await api.cloneEstimate(id)
      emit('change')
      store.commit('setField', {
        field: 'loading', value: true,
      })

    }
    const setEstimateToBeDeleted = async (id) => {
      idOfTheEstimateToBeDeleted.value = id
      showDeleteConfirmation.value = true
    }
    const deleteEstimate = async () => {
      await api.deleteEstimate(idOfTheEstimateToBeDeleted.value)
      showDeleteConfirmation.value = false
      emit('change')
      // await store.dispatch('orders/getOrdersList', {status: props.type.name} )
    }

    return {
      estimate,
      summary,
      previewShow,
      getEstimate,
      cloneEstimate,
      deleteEstimate,
      goToEstimate,
      goToSpecification,
      showDeleteConfirmation,
      setEstimateToBeDeleted,
      infoId
    }
  },
})
</script>


<style lang="scss" scoped>
.cards {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-right: 43px;
    margin-bottom: 30px;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    @media(max-width: 768px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 768px) {
  .cards {
    &__item {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
}
</style>
