<template>
    <div class="sort">
        <button class="button_sort"
                v-for="(item, index) in items"
                :key="`sort-${index}`"
                :class="(item.key == modelValue || '-'+item.key == modelValue) ? 'button_sort--active' : 'button_sort--inactive'"
                @click="updItem(item.key)">
          <p>{{ item.title }}</p>
          <div v-if="item.key == modelValue || '-'+item.key == modelValue">
            <svg-icon-qouting v-show="item.key == modelValue" data="fas fa-arrow-up"/>
            <svg-icon-qouting v-show="'-'+item.key == modelValue" data="fas fa-arrow-down"/>
          </div>
        </button>
<!--        <div class="sort__item-container"  -->
<!--            :class="{ active: item.key == modelValue }"-->
<!--            v-for="(item, index) in items" -->
<!--            :key="`sort-${index}`"-->
<!--             @click="updItem(item.key)">-->
<!--            <div class="sort__item" -->
<!--                >-->
<!--                {{ item.title }}-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import { reactive, ref } from 'vue'
export default ({
  props: {
    items: {
      default: []
    },
    modelValue: {
      default: null
    },
  },
    emits: ['pick'],
    setup(props, { emit }) {
        function updItem(selected) {
            const currentItem = props.modelValue
            const value = currentItem !== selected ? selected : '-'+selected
          emit('update:modelValue', value)
        }
        return {
            updItem,
        }
    },
})
</script>


<style scope lang="scss">
.sort {
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 768px) {
        width: 100%;
      justify-content: flex-start;
    }
    &__item {
        font-size: 15px;
        line-height: 100%;
        white-space: nowrap;
        color: var(--SecondaryColour);
        padding-top: 5px;
        padding-bottom: 4px;
        border-bottom: 1px dashed var(--SecondaryColour);
        cursor: pointer;
        &-container {
            padding: 0 15px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 25px;
            &:last-of-type {
                margin-right: 0;
            }
            &.active {
                color: var(--SecondaryTextColour);
                padding: 0 15px;
                background: var(--SecondaryColour);
                border-radius: 30px;
                .sort__item {
                    color: var(--SecondaryTextColour);
                }
            }
        }
    }
}
.button_sort {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  font-size: 15px;
  margin: 0 20px;
  height: 30px;
  border: 0;
  cursor: pointer;
  &--active {
    background: var(--SecondaryColour);
    border-radius: 30px;
    color: var(--SecondaryTextColour);
    padding: 10px 15px;
  }
  &--inactive {
    background-color: transparent;
    color: var(--SecondaryColour);
    padding: 10px 0px;
    p {
      border-bottom: 1px dashed var(--SecondaryColour);
    }
  }
  svg {
    font-size: 12px;
    margin-left: 4px;
  }
}
</style>