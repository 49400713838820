import { AJAX_GET, AJAX_POST, AJAX_DELETE } from '@/helpers/ajax.js'

export default {
    async getData(query) {
        return await AJAX_GET('/my-estimates', query)
    },
    async getEstimate(id) {
        return await AJAX_GET(`/my-estimates/${id}`)
    },
    async getEstimateSummary(id) {
        // return await AJAX_GET(`/my-estimates/preview/${id}/summary`)
        return await AJAX_GET(`/my-estimates/${id}/summary`)
    },
    async cloneEstimate(id, payload) {
        return await AJAX_POST(`/my-estimates/${id}/clone`)
    },
    async deleteEstimate(id) {
        return await AJAX_DELETE(`/my-estimates/${id}`)
    }
}