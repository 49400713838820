<template>
    <div class="filter">
        <div class="filter__logo">
            <Logo :num="pagination.total" />
        </div>
        <div class="filter__right">
            <Sort class="filter__sort" :items="sortItems" v-model="filterOptions.sort" @update:modelValue="$emit('filter')"/> 
            <InputGroup class="filter__search" :data="searchOptions" v-model="filterOptions[`filter[search]`]" @update:modelValue="$emit('filter')" />
        </div>
    </div>

    <PageHeader elementClass="my-estimate">
        <div class="container md:tw-px-1">
            <div class="filter">
                <div class="filter__logo tw-flex tw-relative tw-w-full md:tw-w-auto tw-border-0 tw-border-b tw-border-solid tw-border-gray-400 md:tw-border-none">
                    <Logo :num="pagination.total" class="tw-p-2"/>
                    <div class="md:tw-hidden tw-flex tw-divide-x tw-divide-y-0 tw-divide-double tw-divide-gray-400 tw-absolute tw-top-0 tw-right-0 tw-h-full filter-primary">
                        <div class="btn-link" @click="showSearch = !showSearch">
                            <svg-icon-qouting data="fas fa-magnifying-glass" />
                        </div>
                        <div class="btn-link" @click="showSortAction = !showSortAction">
                            <svg-icon-qouting data="fas fa-arrow-up-arrow-down" />
                        </div>
                    </div>
                </div>
                <div class="filter__right tw-w-full md:tw-w-auto tw-px-2 md:tw-px-0">
                    <div class="md:tw-flex" :class="showSortAction? 'tw-flex': 'tw-hidden'">
                        <Sort class="filter__sort" :items="sortItems" v-model="filterOptions.sort" @update:modelValue="$emit('filter')"/> 
                    </div>
                    <InputGroup class="filter__search tw-py-2 md:tw-block" :class="showSearch? 'tw-block': 'tw-hidden'" :data="searchOptions" v-model="filterOptions[`filter[search]`]" @update:modelValue="$emit('filter')" />
                </div>
            </div>
        </div>
    </PageHeader>
</template>

<script>
import Sort from './filter/Sort'
import InputGroup from '@/components/form/InputGroup'
import Logo from '@/components/elements/Logo'
import PageHeader from '@/components/item-wizard/components/elements/PageHeader.vue';
import { ref } from 'vue';
export default ({
    props: ['filterOptions', 'pagination'],
    components: {
        Sort,
        InputGroup,
        Logo,
        PageHeader,
    },
    setup() {
        const sortItems = [
            { title: 'By price', key: 'by_price' },
            { title: 'By date', key: 'by_date' },
        ]

        const searchOptions = {
            placeholder: 'Search for everything', type: 'text'
        }

        const showSearch = ref(0)
        const showSortAction = ref(0)

        return { sortItems, searchOptions, showSearch, showSortAction }
    },
})
</script>


<style lang="scss">
    .my-estimate.page-header-global {
        @media(max-width: 768px) {
            padding: 0;
        }
    }
</style>

<style scoped lang="scss">

.filter-primary {
    background: var(--PrimaryButtonColour);
    color: var(--PrimaryButtonTextColour);
}
.page-header-global {
    .logo {
        font-size: 27px;
    }

    .filter__right {
        @media(max-width: 768px) {
            background-color: #59B69233;
        }
    }

    .filter__sort {
        padding: 5px 0;
        @media(max-width: 768px) {
            justify-content: flex-end;
        }
    }
    .filter__search {
        margin: 0
    }

    .btn-link {
        // color: #59B692CC;
        margin: 0;
        @media(max-width: 768px) {
            // background-color: #59B692CC;
            // color: #1C2833CC;
            width: 43px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
            opacity: 0.9;
            }
        }
    }
}
.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width: 768px) {
         &__logo {
            width: 100%;
           text-align: left;
        }
    }
    &__right {
        display: flex;
        align-items: center;
        @media(max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    &__search {
        width: 350px;
        margin-left: 25px;
        width: 100%;
        @media(max-width: 768px) {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}
@media (max-width: 768px) {
  .filter {
    &__right {
      flex-wrap: wrap;
    }
    &__sort {
      padding: 12px 0;
    }
    &__search {
      width: 100%;
      margin-left: 0;
    }
  }
}

</style>