import { useQuery } from "vue-query";
import api from '@/components/item-wizard/services/item.service.js'


const fetchData = async (id) => {
  const response = await api.getData(id, 'my-estimates', 'actions')
  return response;
};

export default function useGetActionItems(id) {

  const queryKey = ["action-items", { id }];

  return useQuery(
    queryKey,
    () => fetchData(id),
    {
      refetchOnWindowFocus: false,
      //   placeholderData: [],
      //   staleTime: Infinity,
      onError: (error) => {
      },
    }
  );
}
