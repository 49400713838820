<template>
    <div class="dashboard container_content" v-if="data.cards">
        <Filter :pagination="data.pagination" class="dashboard__filter" :filterOptions="filterOptions" @filter="getData(filterOptions)"/>
        <Cards v-if="data.cards.length" :items="data.cards" class="dashboard__cards" @change="getData"/>
        <div v-else class="no-result">
           <p v-if="filterOptions['filter[search]']" class="text text_italic text_green">No estimates found matching your search criteria.</p>
           <p v-else class="text text_italic text_green">You have not created any estimates yet.</p>
        </div>
    </div>
</template>

<script>
import Filter from '@/components/dashboard/Filter'
import Cards from '@/components/dashboard/Cards'
import api from '@/services/estimate/dashboard.service.js'
import { ref, reactive, onMounted, onUnmounted } from 'vue'
import {_} from 'vue-underscore';
import { useStore } from 'vuex'
import { usePaginationLogic } from '@/composables/pagination'

export default({
    components: {
        Filter,
        Cards
    },
    setup(props, context) {
        const store = useStore()
        const data = ref({
            cards: null,
            pagination: null
        })
        let filterOptions = reactive({
            sort: '',
            'filter[search]': '',
            paginate: 20,
        });
        if(localStorage.getItem('filterOptions')) {
          filterOptions = JSON.parse(localStorage.getItem('filterOptions'))
        }



        store.commit('setField', {
            field: 'loading', value: true,
        })

      const getData = _.debounce(async (query = filterOptions) => {
        localStorage.setItem('filterOptions', JSON.stringify(query))
        const result = await api.getData(query)
        if(result) {
          data.value = {
            cards: result.data,
            pagination: result.pagination
          }
        }
        store.commit('setField', {
          field: 'loading', value: false,
        })
      }, 200);

        const paginationLogic = usePaginationLogic({
            data: data,
            options: filterOptions,
            perPage: 20,
            pageSelector: '.dashboard',
            methods: {
                getData,
            }
        })

        onMounted(() => {
            paginationLogic.start()
        })

        onUnmounted(() => {
            paginationLogic.end()
        })


      getData()

        return {
            data,
            filterOptions,
            getData
        }

    },
})
</script>


<style scoped lang="scss">

.no-result {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: gray;
}
.dashboard {
    &__filter {

    }
    &__cards {
        margin-top: 31px;
    }
}
</style>
