<script setup>
    import {computed} from 'vue'
    import useGetActionItems from './useGetActionItems'

    const props = defineProps(['actions', 'state', 'id'])
    const emits = defineEmits(['event'])

    const availableActions = ['clone','delete','specification']

    const { data, status} = useGetActionItems(props.id)

    const actions = computed(() => {
        const result = []
        data.value?.forEach(action => {
            if(availableActions.includes( action.name.toLowerCase())) {
                result.push({
                    title: action.name,
                    event: action.name.toLowerCase(),
                    icon: action.icon
                })
            }
        })
        return result
    })
</script>

<template>
<div v-if="status === 'loading'" class="actions__item">Loading....</div>

<div v-else class="actions__item"
    v-for="item in actions"
    :class="{'disabled' : item.title === 'Delete' && state === 'Draft'}"
    :key="item.event"
    @click="$emit('event', item.event)"
>
    <svg-icon-qouting :data="item.icon" />
    <span>{{ item.title }}</span>
</div>
</template>

<style scoped>
.actions__item {
    margin-bottom: 10px;
    text-align: right;
    text-transform: uppercase;
    padding: 0 10px;
    font-weight: 600;
    cursor: pointer;
    &:last-of-type {
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      margin-left: 5px;
    }
}
</style>