import { ref, reactive, onMounted } from 'vue'
export function usePaginationLogic(props) {

    const data = reactive({
        pageHeight: null,
        marginTop: 80,
        lastPosition: 0,
        loading: false
    })

    function start() {
        window.addEventListener('scroll', checkScroll)
    }

    function end() {
        window.removeEventListener('scroll', checkScroll)
    }

    function checkHeightPage() {
        const page = document.querySelector(props.pageSelector)
        return page && page.clientHeight
    }

    async function checkScroll() {
        const oldValue = data.pageHeight 
        const newValue = checkHeightPage() 

        if(oldValue !== newValue) data.pageHeight = newValue
        const quarterPageHeight = data.pageHeight / 4 //if 25 percent of the way has passed, then load the data
        const currentScrollPosition = window.scrollY + data.marginTop
        const totalElements = props.data.value.pagination.total 

        if(
            props.options.paginate <= totalElements 
            && (currentScrollPosition > quarterPageHeight && currentScrollPosition > data.lastPosition || oldValue == newValue)
            && !data.loading 
        ) {	
            //start load data
            data.loading = true
            props.options.paginate +=  props.perPage
            await props.methods.getData() 
            data.lastPosition = currentScrollPosition
            data.pageHeight = checkHeightPage()
            data.loading = false
        }
    }

    return {
        start,
        end
    }

}
