<template>
    <div class="logo">
        my estimates <span class="logo__num" v-if="num">{{ num }}</span>
    </div>

</template>

<script>
export default ({
    props: ['num']
})
</script>


<style scoped lang="scss">
.logo {
    font-family: Sansation;
    font-weight: bold;
    font-size: 31px;
    line-height: 100%;
    text-transform: uppercase;
    &__num {
        color: var(--SecondaryColour)
    }
}
</style>