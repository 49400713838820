<template>
    <div class="card">
        <div class="card__top" :class="{requested: data.dealer_leads_count > 0}">
            <div class="top__name tw-cursor-pointer" @click="$emit('onClick')">
                <div class="top__icon"></div>
              {{ data.container_id }} {{data.dealer_leads_count > 0 ? 'Estimate' : 'Draft'}}
            </div>
          <div class="actions__info" @click="$emit(`get`, data.container_id)"><svg-icon-qouting data="fas fa-cart-shopping" /></div>
          <div class="top__actions" v-if="data.dealer_leads_count <= 1">
            <div class="top__actions-container" @click="showDropdown = !showDropdown">
              <svg-icon-qouting data="fas fa-ellipsis-h" />
            </div>
            <div v-if="showDropdown" class=" tw-fixed tw-inset-0" style="z-index: 9999;" @click="showDropdown = false"></div>
            <div class="actions__dropdown tw-relative" :style="{zIndex: showDropdown? '99990': 'unset'}" v-if="showDropdown">
              
              <CardActionItemsVue :id="data.container_id" :actions="actions" :state="data.state" @event="(event) => {$emit(`${event}`, data.container_id), showDropdown = !showDropdown}" />

            </div>
          </div>
        </div>
        <div class="card__content">
<!--            <div class="card__content__unavailable" v-if="data.dealer_leads_count > 1">-->
<!--              <div class="card__content__unavailable__text">-->
<!--                The dealer has rejected this estimate. We are looking for another one [TBC]-->
<!--              </div>-->
<!--            </div>-->

            <div class="content__item">
                <div class="item__icon" v-if="data.card_status?.icon">
                    <svg-icon-qouting :data="data.card_status?.icon"/>
                </div>
                <div class="item__text">{{(data.card_status?.text ?? 'Draft')}}</div>
                <!-- {{ getStatus(data.current_state) }} -->
            </div>
            <div class="content__item" v-if="data.number_of_items">
              <div class="item__icon">
                <svg-icon-qouting data="fas fa-layer-group"/>
              </div>
              <div class="item__text">Number of items: {{ data.number_of_items }}</div>
            </div>
            <div class="content__item">
              <div class="item__icon">
                <svg-icon-qouting data="fas fa-tools" />
              </div>
              <div class="item__text">Installation: {{ formatter.formatMoney(data.installation_price) }}</div>
            </div>
            <div class="content__item" v-if="data.total">
                <div class="item__icon">
                    <svg-icon-qouting data="far fa-money-bill-alt"/>
                </div>
                <div class="item__text" style="font-weight: 700; font-size: 17px">{{ formatter.formatMoney(data.total) }}</div>
            </div>
            <Button class="card__btn tw-flex tw-items-center tw-h-auto" style="height: auto; display: flex; align-items: center;" :data="{ type: 'primary', title: data.dealer_leads_count > 0? 'VIEW': 'EDIT', icon:  data.dealer_leads_count > 0? 'fas fa-eye': 'fas fa-edit' }" @click="$emit('onClick')"/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/form/Button'
import { useFormatter } from '@/composables/formatter'
import { ref, computed } from 'vue'
import CardActionItemsVue from '@/components/dashboard/cards/CardActionItems.vue'

export default({
    props: ['data'],
    components: {
        Button,CardActionItemsVue
    },
    setup() {
      const formatter = useFormatter()
      const actions = [
        {
          title: 'Clone',
          event: 'clone',
          icon: 'fas fa-clone',
        },
        {
          title: 'Delete',
          event: 'delete',
          icon: 'fas fa-trash-alt',
        },
        {
          title: 'Specification',
          event: 'specification',
          icon: 'fas fa-file-alt',
        }
      ]
      const showDropdown = ref(false)

      return {
        formatter,
        actions,
        showDropdown
      }
    }
})
</script>


<style scoped lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.top {
    &__name {
        font-weight: bold;
        font-size: 15px;
        line-height: 100%;
        text-transform: uppercase;
        color: var(--PrimaryColour);
        display: flex;
        align-items: center;
      padding: 10px 0;
    }
    &__icon {
        margin-right: 5px;
    }
  &__actions {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
   // cursor: pointer;
    position: relative;
    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.2s;
      background-color: #E5E5E5;
      cursor: pointer;
      &:hover {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15));
      }
    }
  }
}
.content {
    &__item {
        // width: calc(50% - 12px);
        margin-bottom: 14px;
        display: flex;
         align-items: center;
        .item {
            &__icon {
                margin-right: 5px;
            }
            &__text {
                font-size: 15px;
                line-height: 140%;
                color: rgba(28, 40, 51, 0.8);
                text-align: left;
                white-space: nowrap;
                &.bold {
                    font-weight: 600;
                }
            }
        }
    }
}
.card {
    width: 350px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    @media(max-width: 768px) {
        width: 100%;
    }
    &__top, &__content {
        display: flex;
        justify-content: space-between;
    }
    &__top {
        align-items: center;
        padding-left:9px;
        padding-right:9px;
        background: #E5E5E5;
        border-radius: 2px 2px 0px 0px;
         position: relative;

      &.requested{
        background-color: var(--PrimaryColour);
        .top__name, .actions__info{
          color: var(--PrimaryTextColour);
        }

        .top__actions {
          &-container {
            color: var(--PrimaryTextColour);
            background-color: var(--PrimaryColour);
          }

        }
      }
    }
    &__content {
        padding: 12px 9px;
        flex-wrap: wrap;
        min-height: 142px;
        column-gap: 3px;
        display: grid;
        grid-template-columns: repeat(2,minmax(0,1fr));
      position: relative;

      &__unavailable{
        z-index: 1;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        padding: 20px;
        background: rgba(0, 0, 0, 0.75);

        &__text{
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          //position: absolute;
          text-align: center;
          align-content: center;
          background: rgba(255, 255, 255, 0.85);
          padding: 15px;
        }
      }

    }
    &__btn {
        width: 100%;
        margin-top: auto;
        grid-column: span 2/span 2;
    }
}

.actions {
  &__dropdown {
    position: absolute;
    right: 0;
    top: 30px;
    min-width: 160px;
    width: auto;
    padding: 10px 0;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF;
    border: 1px solid rgba(11, 53, 83, 0.2);
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.1);
    z-index: 10;
  }
  &__item {
    margin-bottom: 10px;
    text-align: right;
    text-transform: uppercase;
    padding: 0 10px;
    font-weight: 600;
    cursor: pointer;
    &:last-of-type {
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      margin-left: 5px;
    }
  }
  &__info {
    // border: 1px solid #C4C4C4;
    border-top: none;
    border-bottom: 0;
    width: 24px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:last-child{
      border-right: none;
    }
  }
}

</style>
